import { createAsyncThunk } from "@reduxjs/toolkit";
import { CreateUserData } from "../interfaces/user.interface";

export const FetchUserDetails = createAsyncThunk(
  "fetchUserDetails",
  async (
    payload: { userId: string; token: string; force?: boolean },
    { getState, rejectWithValue, fulfillWithValue }
  ) => {
    const data: any = getState();

    if (!payload.force) {
      // check if the user has already been pulled in the state and return it
      const userDetails = data.user.users.find(
        (user) => user.user_id === payload.userId
      );
      if (userDetails) {
        return fulfillWithValue(userDetails);
      }
    }

    // in case the user is not present in the state. Let fetch it from the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.userId}`,
        {
          method: "GET",
          headers: { Authorization: payload.token },
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        return fulfillWithValue(jsonResponse);
      }
      return rejectWithValue(jsonResponse.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUserSitesDetails = createAsyncThunk(
  "fetchUserSitesDetails",
  async (
    payload: { userId: string; token: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    // in case the user is not present in the state. Let fetch it from the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.userId}/sites`,
        {
          method: "GET",
          headers: { Authorization: payload.token },
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        return fulfillWithValue(jsonResponse);
      }
      return rejectWithValue(jsonResponse.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const FetchUserGroupsDetails = createAsyncThunk(
  "fetchUserGroupsDetails",
  async (
    payload: { userId: string; token: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    // in case the user is not present in the state. Let fetch it from the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.userId}/groups`,
        {
          method: "GET",
          headers: { Authorization: payload.token },
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        return fulfillWithValue(jsonResponse);
      }
      return rejectWithValue(jsonResponse.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateUserGroupsDetails = createAsyncThunk(
  "UpdateUserGroupsDetails",
  async (
    payload: { userId: string; token: string; groups: number[] },
    { rejectWithValue, fulfillWithValue, dispatch }
  ) => {
    // in case the user is not present in the state. Let fetch it from the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users/${payload.userId}/groups`,
        {
          method: "PUT",
          headers: { Authorization: payload.token },
          body: JSON.stringify({ groups: payload.groups }),
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        dispatch(
          FetchUserSitesDetails({
            userId: payload.userId,
            token: payload.token,
          })
        );
        return fulfillWithValue(jsonResponse);
      }
      return rejectWithValue(jsonResponse.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const CreateUser = createAsyncThunk(
  "CreateUser",
  async (
    payload: { data: CreateUserData; token: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    // in case the user is not present in the state. Let fetch it from the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users`,
        {
          method: "POST",
          headers: { Authorization: payload.token },
          body: JSON.stringify({
            ...payload.data,
            active: payload.data.active ? 1 : 0,
          }),
        }
      );
      const jsonResponse = await response.json();
      if (response.ok) {
        return fulfillWithValue(jsonResponse);
      }
      return rejectWithValue(jsonResponse.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const UpdateUserDetails = createAsyncThunk(
  "UpdateUserDetails",
  async (
    payload: { data: any; token: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    // in case the user is not present in the state. Let fetch it from the backend
    try {
      const response = await fetch(
        `${process.env.REACT_APP_PFS_BASE_URL}/users`,
        {
          method: "POST",
          headers: { Authorization: payload.token },
          body: JSON.stringify({
            ...payload.data,
            active: payload.data.active ? 1 : 0,
          }),
        }
      );
      const jsonResponse = await response.json();
      console.log("json response", jsonResponse);
      if (response.ok) {
        return fulfillWithValue(jsonResponse);
      }
      return rejectWithValue(jsonResponse.message);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
